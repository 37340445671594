import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import { Field, Formik } from 'formik';

import usersGroupIcon from 'assets/images/users-group.svg';
import { useCreateCaseGroupForm } from './hooks/useCreateCaseGroupForm';
import { ICaseGroup } from 'interfaces/caseGroups';
import { useCreateCaseGroupFormTranslations } from './hooks/useCreateCaseGroupFormTranslations';
import InputFieldWithoutImage from '../../../../components/InputFieldWithoutImage';
import FieldSelect from '../../../../components/FieldSelect';
import FieldInput from '../../../../components/FieldInput';

import saveIcon from 'assets/images/floppy-disc.svg';
import resetIcon from 'assets/images/revert-arrow.svg';
import GroupSchedule from './components/GroupSchedule';
import { useCaseGroupsCommonData } from '../../hooks/useCaseGroupsCommonData';
import CreateCaseGroupStartingDate from './components/CreateCaseGroupStartingDateField';
import CreateCaseGroupProjectField from './components/CreateCaseGroupProjectField';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';

type CreateCaseGroupModalProps = {
  isOpen: boolean;
  toggle: () => void;
  caseGroup?: ICaseGroup;
  refetch?: () => Promise<void>;
};

const CreateCaseGroupModal = ({
  isOpen,
  toggle,
  caseGroup,
}: CreateCaseGroupModalProps) => {
  const { caseGroups, treatmentPlans, caseCategories } =
    useCaseGroupsCommonData();

  useInitQuery(treatmentPlans);
  useInitQuery(caseCategories);

  const { initialValues, validation, handleSubmit, options } =
    useCreateCaseGroupForm(caseGroup, caseGroups.refetch, toggle);

  const translations = useCreateCaseGroupFormTranslations(!!caseGroup);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="CreateCaseGroupModal"
    >
      <ModalHeader
        className="CreateCaseGroupModal__header CreateCaseGroupModalHeader"
        toggle={toggle}
      >
        <SVG src={usersGroupIcon} title={translations.modalTitle} />
        <FormattedMessage id={translations.modalTitle} />
      </ModalHeader>
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, values, setValues, resetForm, dirty }) => {
          const handleReset = () => {
            resetForm();
            toggle();
          };

          return (
            <>
              <ModalBody className="CreateCaseGroupModal__body CreateCaseGroupModalBody">
                <Row>
                  <Col xs={12}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={false}
                      labelTranslationId={translations.nameLabel}
                      inputId="name"
                    >
                      <Field
                        id="name"
                        name="name"
                        placeholder={translations.namePlaceholder}
                        type="text"
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={true}
                      labelTranslationId={translations.organizationLabel}
                      inputId="organization_id"
                    >
                      <Field
                        id="organization_id"
                        name="organization_id"
                        type="select"
                        options={options.organization}
                        displayEmpty={false}
                        disabled={true}
                        component={FieldSelect}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                  <Col xs={4}>
                    <CreateCaseGroupProjectField
                      values={values}
                      setValues={setValues}
                      translations={translations}
                      options={options}
                    />
                  </Col>
                  <Col xs={4}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      labelTranslationId={translations.caseCategoryLabel}
                      inputId="case_category_id"
                    >
                      <Field
                        id="case_category_id"
                        name="case_category_id"
                        type="select"
                        defaultText={translations.caseCategoryPlaceholder}
                        options={options.treatmentPlans}
                        required={true}
                        component={FieldSelect}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      labelTranslationId={translations.maximumParticipantsLabel}
                      inputId="maximum_members_allowed"
                    >
                      <Field
                        id="maximum_members_allowed"
                        name="maximum_members_allowed"
                        placeholder={
                          translations.maximumParticipantsPlaceholder
                        }
                        type="number"
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                  <Col xs={6}>
                    <CreateCaseGroupStartingDate
                      values={values}
                      setValues={setValues}
                      translations={translations}
                    />
                  </Col>
                </Row>
                {values.project_id && values.case_category_id && (
                  <Col xs={12}>
                    <InputFieldWithoutImage
                      gray={true}
                      block={true}
                      noMargin={true}
                      labelTranslationId={translations.groupSchedules}
                      inputId="group_schedule"
                    >
                      <div className="CreateCaseGroupModalBody__group-schedule CreateCaseGroupModalBodyGroupSchedule">
                        <GroupSchedule values={values} />
                      </div>
                    </InputFieldWithoutImage>
                  </Col>
                )}
              </ModalBody>
              <ModalFooter className="CreateCaseGroupModal__footer CreateCaseGroupModalFooter">
                <Button
                  color="primary"
                  outline={true}
                  onClick={submitForm}
                  disabled={!isValid || !dirty}
                >
                  <SVG src={saveIcon} title={translations.saveAction} />
                </Button>
                <Button color="primary" outline={true} onClick={handleReset}>
                  <SVG src={resetIcon} title={translations.resetAction} />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateCaseGroupModal;
