import React, { useMemo } from 'react';
import { ISummarizedBaseCaseGroup } from '../../../../interfaces/caseGroups';
import CustomTable from '../../../../components/CustomTable';
import CaseGroupListItem from './components/CaseGroupListItem';

type CaseGroupsListProps = {
  caseGroups: ISummarizedBaseCaseGroup[];
};

const headerItems = [
  'General.Name',
  'CaseGroups.CaseGroupsList.project',
  'CaseGroups.CaseGroupsList.treatmentPlan',
  'CaseGroups.CaseGroupsList.startingDate',
  'CaseGroups.CaseGroupsList.groupSequence',
  'CaseGroups.CaseGroupsList.participantsCount',
  'CaseGroups.CaseGroupsList.maximumParticipantsCount',
  'CaseGroups.CaseGroupsList.actions',
];

const CaseGroupsList = ({ caseGroups }: CaseGroupsListProps) => {
  const bodyRows = useMemo(() => {
    return (caseGroups ?? []).map((caseGroup) => (
      <CaseGroupListItem key={caseGroup.id} caseGroup={caseGroup} />
    ));
  }, [caseGroups]);

  return (
    <>
      {headerItems && bodyRows && (
        <div className="mt-5">
          <CustomTable
            isResponsive={true}
            stretched={false}
            headerItems={headerItems}
            bodyRows={bodyRows}
          />
        </div>
      )}
    </>
  );
};

export default CaseGroupsList;
