import { ICaseGroupFormValues } from './useCreateCaseGroupForm';
import { FormikErrors } from 'formik';
import { differenceInCalendarDays } from 'date-fns';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';

export const useCreateCaseGroupFormValidation = () => {
  const intl = useIntl();

  const validateSingleFields = useCallback(
    (
      values: ICaseGroupFormValues,
      errors: FormikErrors<ICaseGroupFormValues>
    ) => {
      if (values.project_id.length === 0) {
        errors['project_id'] = intl.formatMessage({
          id: 'CaseGroups.CreateCaseGroupModal.projectInvalid',
        });
        return errors;
      }

      if (values.name.length === 0) {
        errors['name'] = intl.formatMessage({
          id: 'CaseGroups.CreateCaseGroupModal.nameInvalid',
        });
        return errors;
      }

      if (values.case_category_id.length === 0) {
        errors['case_category_id'] = intl.formatMessage({
          id: 'CaseGroups.CreateCaseGroupModal.caseCategoryInvalid',
        });
        return errors;
      }

      if (
        differenceInCalendarDays(new Date(), new Date(values.starting_date)) > 0
      ) {
        errors['starting_date'] = intl.formatMessage({
          id: 'CaseGroups.CreateCaseGroupModal.startingDateInvalid',
        });
        return errors;
      }

      if (values.maximum_members_allowed <= 0) {
        errors['maximum_members_allowed'] = intl.formatMessage({
          id: 'CaseGroups.CreateCaseGroupModal.maximumMembersAllowedInvalid',
        });
        return errors;
      }

      return errors;
    },
    [intl]
  );

  const validateGroupSchedule = useCallback(
    (
      values: ICaseGroupFormValues,
      errors: FormikErrors<ICaseGroupFormValues>
    ) => {
      values.group_schedule.forEach((schedule, index) => {
        if (schedule.phase_id.length === 0) {
          errors[`group_schedule[${index}].phase_id`] = intl.formatMessage({
            id: 'CaseGroups.CreateCaseGroupModal.phaseInvalid',
          });
          return;
        }

        if (schedule.date_from > schedule.date_to) {
          errors[`group_schedule[${index}].date_from`] = intl.formatMessage({
            id: 'CaseGroups.CreateCaseGroupModal.dateFromInvalid',
          });
          return;
        }

        const beforeDate =
          index === 0
            ? values.starting_date
            : values.group_schedule[index - 1].date_to;

        if (schedule.date_from < beforeDate) {
          errors[`group_schedule[${index}].date_from`] = intl.formatMessage({
            id: 'CaseGroups.CreateCaseGroupModal.dateFromInvalid',
          });
        }
      });

      return errors;
    },
    [intl]
  );

  return {
    singleFieldsValidation: validateSingleFields,
    groupScheduleValidation: validateGroupSchedule,
  };
};
