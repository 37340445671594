import { useIntl } from 'react-intl';
import { useMemo } from 'react';

export const useCreateCaseGroupFormTranslations = (
  editMode: boolean = false
) => {
  const intl = useIntl();

  const modalTitle = useMemo(() => {
    if (editMode)
      return intl.formatMessage({
        id: 'CaseGroups.CreateCaseGroupModal.editCaseGroup',
      });

    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.addCaseGroup',
    });
  }, [editMode, intl]);

  const caseCategoryPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.caseCategoryPlaceholder',
    });
  }, [intl]);

  const maximumParticipantsPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.maximumParticipantsPlaceholder',
    });
  }, [intl]);

  const namePlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.namePlaceholder',
    });
  }, [intl]);

  const startingDatePlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.startingDatePlaceholder',
    });
  }, [intl]);

  const fromPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.GroupSchedule.fromPlaceholder',
    });
  }, [intl]);

  const toPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.GroupSchedule.toPlaceholder',
    });
  }, [intl]);

  const phasePlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.GroupSchedule.phasePlaceholder',
    });
  }, [intl]);

  const groupSchedules = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.GroupSchedule.groupSchedules',
    });
  }, [intl]);

  const projectPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'CaseGroups.CreateCaseGroupModal.projectPlaceholder',
    });
  }, [intl]);

  const organizationLabel: string =
    'CaseGroups.CreateCaseGroupModal.organization';
  const caseCategoryLabel: string =
    'CaseGroups.CreateCaseGroupModal.caseCategory';
  const maximumParticipantsLabel: string =
    'CaseGroups.CreateCaseGroupModal.maximumParticipants';
  const startingDateLabel: string =
    'CaseGroups.CreateCaseGroupModal.startingDate';
  const fromLabel: string =
    'CaseGroups.CreateCaseGroupModal.GroupSchedule.from';
  const toLabel: string = 'CaseGroups.CreateCaseGroupModal.GroupSchedule.to';
  const phaseLabel: string =
    'CaseGroups.CreateCaseGroupModal.GroupSchedule.phase';
  const projectLabel: string = 'CaseGroups.CreateCaseGroupModal.projectLabel';
  const nameLabel: string = 'General.Name';

  const saveAction: string = 'CaseGroups.CreateCaseGroupModal.save';
  const resetAction: string = 'CaseGroups.CreateCaseGroupModal.reset';

  return {
    modalTitle: modalTitle,
    organizationLabel: organizationLabel,
    caseCategoryPlaceholder: caseCategoryPlaceholder,
    caseCategoryLabel: caseCategoryLabel,
    maximumParticipantsLabel: maximumParticipantsLabel,
    maximumParticipantsPlaceholder: maximumParticipantsPlaceholder,
    namePlaceholder: namePlaceholder,
    startingDateLabel: startingDateLabel,
    startingDatePlaceholder: startingDatePlaceholder,
    fromLabel: fromLabel,
    fromPlaceholder: fromPlaceholder,
    toLabel: toLabel,
    nameLabel: nameLabel,
    toPlaceholder: toPlaceholder,
    saveAction: saveAction,
    resetAction: resetAction,
    phaseLabel: phaseLabel,
    phasePlaceholder: phasePlaceholder,
    groupSchedules: groupSchedules,
    projectLabel: projectLabel,
    projectPlaceholder: projectPlaceholder,
  };
};
