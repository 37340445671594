import React from 'react';
import { formatDate } from '../../../../../../helpers/dates';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import useCultures from '../../../../../../hooks/cultures/useCultures';
import { ISummarizedBaseCaseGroup } from '../../../../../../interfaces/caseGroups';
import { useNavigation } from './hooks/useNavigation';
import { useCaseCategories } from './hooks/useCaseCategories';
import { useProjectsTitle } from './hooks/useProjectsTitle';

type CaseGroupListItemProps = {
  caseGroup: ISummarizedBaseCaseGroup;
};

const CaseGroupListItem = ({ caseGroup }: CaseGroupListItemProps) => {
  const intl = useIntl();
  const { currentCulture } = useCultures();

  const { currentCaseCategory, getCurrentCaseCategoryName } =
    useCaseCategories(caseGroup);
  const { projectsTitle, projectsTitleTranslation } =
    useProjectsTitle(caseGroup);
  const { navigateToCaseGroupDetails } = useNavigation(caseGroup);

  return (
    <tr key={caseGroup.id}>
      <td valign="middle">{caseGroup.name ?? '-'}</td>
      <td valign="middle">
        {projectsTitle
          ? projectsTitleTranslation
          : intl.formatMessage({
              id: 'CaseGroups.CaseGroupsList.notDefined',
            })}
      </td>
      <td valign="middle">
        <b>
          {currentCaseCategory
            ? getCurrentCaseCategoryName(
                currentCulture.culture,
                currentCaseCategory
              )
            : intl.formatMessage({
                id: 'CaseGroups.CaseGroupsList.notDefined',
              })}
        </b>
      </td>
      <td valign="middle">{formatDate(caseGroup.starting_date)}</td>
      <td valign="middle">
        <FormattedMessage
          id="CaseGroupItem.caseGroupNumber"
          values={{ number: caseGroup.sequence }}
        />
      </td>
      <td valign="middle">{caseGroup.cases_count}</td>
      <td valign="middle">{caseGroup.maximum_members_allowed}</td>
      <td valign="middle" width="50px">
        <Button
          color="primary"
          outline={true}
          onClick={navigateToCaseGroupDetails}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </td>
    </tr>
  );
};

export default CaseGroupListItem;
