import { faEnvelope, faUserCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdditionalActionsDropdown from 'components/AdditionalActionsDropdown';
import CustomTable from 'components/CustomTable';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import FunctionalityRouteGuard from 'components/routes/FunctionalityRouteGuard';
import { iconColors } from 'helpers/colors';
import { formatDate } from 'helpers/dates';
import {
  generateParticipantCheckRoute,
  RedirectSource,
} from 'helpers/routeDefinitions';
import { getLocalOrganizationFunctionalities } from 'helpers/utils/auth';
import useRequiredParams from 'hooks/useRequiredParams';
import { ICaseListItem } from 'interfaces/cases';
import { FunctionalityCode } from 'interfaces/functionalities';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router';
import { Button, DropdownItem } from 'reactstrap';
import InfiniteListLayoutLoader from '../../../../../../../../../components/InfiniteList/InfiniteListLayoutLoader';
import {
  GroupSelectionIdentifiers,
  IRowSelection,
} from '../../../../../../../../../hooks/useRowSelection';
import EmptyInformation from '../../../../../../../../TimelineDetails/EmptyInformation';
import CasesCustomTablePlaceholder from './CasesCustomTablePlaceholder';
import CasesListLastFeedbackLabel from './CasesListLastFeedbackLabel';

type CasesTableRendererProps = {
  rowSelection: IRowSelection<GroupSelectionIdentifiers>;
  hasMore?: boolean;
  fetchNextPage: () => void;
  cases: ICaseListItem[];
  onToggleRow?: (caseId: string) => void;
  onToggleMessage?: (caseId: string) => void;
  onRowClick: (caseData: ICaseListItem) => void;
  isLoading: boolean;
};

export type headerItemType = JSX.Element | string;

const CasesTableRenderer = ({
  rowSelection,
  fetchNextPage,
  isLoading,
  hasMore = false,
  cases,
  onRowClick,
  onToggleMessage,
  onToggleRow,
}: CasesTableRendererProps) => {
  const navigate = useNavigate();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const localOrganizationFunctionalities =
    getLocalOrganizationFunctionalities();

  const headerItems = useMemo(() => {
    let localHeaderItems: headerItemType[] = [
      'OrganizationCases.CasesList.HeaderItems.participant',
      'CaseCard.title',
      'CaseCard.caseNumber',
      'Cases.CasesList.CasesTableRenderer.caseGroupNumber',
      'Cases.CasesList.CasesTableRenderer.lastFeedback',
      'OrganizationCases.CasesList.HeaderItems.lastSynchronization',
      'CaseCard.category',
      'General.status',
    ];

    if (
      localOrganizationFunctionalities?.includes(
        FunctionalityCode.CASE_LIST_SEND_MESSAGE_GROUP
      )
    ) {
      localHeaderItems = [
        <IndeterminateCheckbox
          key="indeterminate-checkbox"
          checked={rowSelection.isSelectAllChecked}
          indeterminate={rowSelection.isSelectAllIndeterminate}
          onChange={rowSelection.toggleAllRows}
        />,
        ...localHeaderItems,
      ];
    }

    return localHeaderItems;
  }, [localOrganizationFunctionalities, rowSelection]);

  const bodyRows = cases.map((singleCase) => {
    const [participant] = singleCase?.participants || [];

    const onToggleRowInCaseRow = () => {
      if (onToggleRow) onToggleRow(singleCase.id);
    };

    const onRowClickInCase = () => {
      onRowClick(singleCase);
    };

    const onToggleMessageInCaseRow = () => {
      if (onToggleMessage) onToggleMessage(singleCase.id);
    };

    const triggerPatientCheckModal = () =>
      navigate(
        generateParticipantCheckRoute(
          {
            caseId: singleCase.id,
            organizationId,
          },
          RedirectSource.caseList
        )
      );

    return (
      <tr className="cursor-pointer CasesListItem" key={singleCase.id}>
        {rowSelection && (
          <FunctionalityRouteGuard
            code={FunctionalityCode.CASE_LIST_SEND_MESSAGE_GROUP}
          >
            <td>
              <input
                type="checkbox"
                checked={rowSelection.isRowSelected(singleCase.id)}
                onChange={onToggleRowInCaseRow}
              />
            </td>
          </FunctionalityRouteGuard>
        )}
        <td onClick={onRowClickInCase}>
          <b>{`${participant?.personality?.first_name ?? ''} ${
            participant?.personality?.last_name ?? ''
          }`}</b>
        </td>
        <td onClick={onRowClickInCase}>
          <b>{singleCase.title}</b>
        </td>
        <td>{singleCase.number}</td>
        <td>
          {singleCase.case_group
            ? singleCase.case_group.name ?? singleCase.case_group.sequence
            : '-'}
        </td>
        <td onClick={onRowClickInCase}>
          {singleCase?.participant_feedback_date ? (
            <div>
              <span>
                {formatDate(singleCase?.participant_feedback_date.toString())}
              </span>
              <div>
                <CasesListLastFeedbackLabel
                  status={singleCase?.participant_feedback_status}
                />
              </div>
            </div>
          ) : (
            '-'
          )}
        </td>
        <td onClick={onRowClickInCase}>
          {singleCase?.participant_last_synchronization_date ? (
            <div>
              <span>
                {formatDate(
                  singleCase?.participant_last_synchronization_date.toString()
                )}
              </span>
              <div>
                <CasesListLastFeedbackLabel
                  status={singleCase?.participant_synchronization_status}
                />
              </div>
            </div>
          ) : (
            '-'
          )}
        </td>
        <td onClick={onRowClickInCase} className="CasesListItem__category">
          <div className="d-flex flex-column gap-1">
            <span className="CasesListItem__category__main">
              {singleCase?.category?.name ?? '-'}
            </span>
            {singleCase?.sub_category?.name && (
              <span className="CasesListItem__category__sub">
                {singleCase?.sub_category.name}
              </span>
            )}
          </div>
        </td>
        <td onClick={onRowClickInCase}>{singleCase.status}</td>
        <td>
          <div className="d-flex align-items-center gap-2">
            <FunctionalityRouteGuard
              code={FunctionalityCode.CASE_LIST_SEND_MESSAGE}
            >
              <Button
                color="primary"
                outline={true}
                onClick={onToggleMessageInCaseRow}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </Button>
            </FunctionalityRouteGuard>
            <FunctionalityRouteGuard
              code={FunctionalityCode.CASE_LIST_CHECK_PATIENT}
            >
              <AdditionalActionsDropdown
                dropdownItems={
                  <DropdownItem
                    onClick={triggerPatientCheckModal}
                    key="display-action"
                  >
                    <FontAwesomeIcon
                      icon={faUserCheck}
                      color={iconColors.edit}
                      className="me-2"
                    />
                    Check status
                  </DropdownItem>
                }
              />
            </FunctionalityRouteGuard>
          </div>
        </td>
      </tr>
    );
  });

  if (isLoading) {
    return (
      <CasesCustomTablePlaceholder headerItems={headerItems} rowsCount={5} />
    );
  }

  if (cases.length === 0 && hasMore !== true) {
    return (
      <EmptyInformation
        type={'range'}
        graspLogo={false}
        translationId={'OrganizationCases.CasesList.emptyList'}
      />
    );
  }

  return (
    <InfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasMore}
      loader={
        <InfiniteListLayoutLoader
          key="infinite-loader"
          translationId="OrganizationCases.CasesList.fetching"
        />
      }
      threshold={100}
      initialLoad={false}
    >
      <CustomTable
        headerItems={headerItems}
        bodyRows={bodyRows}
        responsiveConfig={[
          {
            breakpoint: 1200,
            hiddenColumns: [3, 5, 6],
          },
          {
            breakpoint: 576,
            hiddenColumns: [2, 3, 4, 5, 6, 7],
          },
        ]}
      />
    </InfiniteScroll>
  );
};

export default CasesTableRenderer;
